import React from 'react';
import '../styles/background.css';

const Background = () => {
  return (
    <div className="background-container">
    </div>
  );
};

export default Background;
